import { Fade } from "react-awesome-reveal";

const HeroSection = ({ title, heading, subHeading }) => {
    return (
        <section>
            <div className='services-background-image'>
                <Fade direction='down' triggerOnce={true}>
                    <div className='container'>
                        <div className='contactus-header '>
                            <div className='d-flex'>
                                <h5>{title}</h5>
                            </div>
                            <h1>{heading}</h1>
                            <p>{subHeading}</p>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
    )
}

export default HeroSection