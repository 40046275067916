import React, { useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import HeroSection from "../Components/HeroSection";
import Footer from "../Components/Footer";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../Components/Spinner";

const GET_CONTACTUSDATA = gql`
  query GetContactUsData {
    heroSection(id: "2MixviPbNvTcPYmqNnAIUz") {
      title
      heading
      headline
    }
  }
`;

const Contact = () => {
  const { loading, error, data } = useQuery(GET_CONTACTUSDATA);
  const [formSubmit, setFormSubmit] = useState(false);

  if (loading) return <Spinner />;
  if (error) return <p>Error : {error.message}</p>;

    const handleSubmit = async (e) => {
      e.preventDefault();
      setFormSubmit(true);

      fetch("https://formsubmit.co/ajax/datalixe@gmail.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          'First Name': e.target.elements.first_name.value,
          'Last Name': e.target.elements.last_name.value,
          Email: e.target.elements.email.value,
          Subject: e.target.elements.subject.value,
          Message: e.target.elements.comments.value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setFormSubmit(false);

          e.target.elements.first_name.value = '';
          e.target.elements.last_name.value = '';
          e.target.elements.email.value = '';
          e.target.elements.subject.value = '';
          e.target.elements.comments.value = '';
        })
        .catch((error) => console.log(error));
    };

  return (
    <div>
      <HeroSection
        title={data?.heroSection.title}
        heading={data?.heroSection.heading}
        subHeading={data?.heroSection.headline}
      />
      <section>
        <div className="contatus-container">
          <Fade direction="up" triggerOnce={true}>
            <div className="contatus-container-main">
              <h2>GET IN TOUCH!</h2>
              <p>
                We're here to help! Please fill out the form below, and we'll
                get back to you soon.
              </p>
            </div>
          </Fade>
          <div className="form-container-header">
            <div className="form-container">
              <div>
                <Fade direction="down" triggerOnce={true}>
                  <div className="d-flex get-in-touch">
                    <div className="d-flex">
                      <i
                        aria-hidden="true"
                        className="fas fa-map-marked-alt mt-1 me-2"
                      ></i>
                      <p>Lagos, NIgeria</p>
                    </div>
                    <div className="d-flex">
                      <i
                        aria-hidden="true"
                        className="fas fa-phone-alt mt-1 me-2"
                      ></i>
                      <p>+234.....</p>
                    </div>
                    <div className="d-flex">
                      <i
                        aria-hidden="true"
                        className="fas fa-mail-bulk mt-1 me-2"
                      ></i>
                      <p>1234@email.com</p>
                    </div>
                  </div>
                </Fade>
              </div>
              <Zoom triggerOnce={true}>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="first_name" className="mb-2 label">
                        First Name <span className="required">*(required)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        placeholder="Input name"
                        required
                      />
                    </div>

                    <div className="form-group col-6">
                      <label htmlFor="last_name" className="mb-2 label">
                        Last Name <span className="required">*(required)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        placeholder="Input name"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12">
                      <label htmlFor="email" className="mb-3 label">
                        Email Address{" "}
                        <span className="required">*(required)</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Input valid email address"
                        required
                      />
                    </div>

                    <div className="form-group col-12">
                      <label htmlFor="subject" className="mb-3 label">
                        Subject{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="How can we help you?"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="comments" className="mb-2 label">
                      Message - Please input full details on what we can help
                      you with
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      name="comments"
                      placeholder="Input text"
                      required
                    ></textarea>
                  </div>
                  <input
                    name="_formsubmit_id"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <div className="contact-btn mt-5">
                    <button type="submit">{formSubmit ? "Loading..." : "Send Message"}</button>
                  </div>
                </form>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
