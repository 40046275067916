import React from "react";
import { Fade } from "react-awesome-reveal";
import HeroSection from "../Components/HeroSection";
import Footer from "../Components/Footer";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../Components/Spinner";

const GET_360BLOGDATA = gql`
  query Get360BlogData {
    heroSection(id: "6CQN8DPpnq8IulfbXzmjTB") {
      title
      heading
      headline
    }
    articlesCollection(limit: 10) {
      items {
        sys {
          id
          publishedAt
        }
        banner {
          url
        }
        title
        headline
        author
      }
    }
  }
`;

const DataBlog = () => {
  const { loading, error, data } = useQuery(GET_360BLOGDATA);

  if (loading) return <Spinner />;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div>
      <HeroSection
        title={data?.heroSection.title}
        heading={data?.heroSection.heading}
        subHeading={data?.heroSection.headline}
      />
      <section>
        <div className="datablog-post-container d-flex container">
          <div className="datablog-post-header d-flex">
            {data?.articlesCollection.items.map((article, index) => (
              <Fade direction="down" triggerOnce={true} delay={index * 200}>
                <div className="datablog-post">
                  <img src={article.banner.url} alt="" />
                  <h6>{article.title}</h6>
                  <div>
                    <i aria-hidden="true" className="fas fa-user me-2"></i>
                    <span>{article.author}</span>
                  </div>
                  <p className="mt-2">{article.headline}</p>
                  <div>
                    <a href={`/blog/${article.sys.id}`}>
                      <button>
                        Read more
                        <i
                          aria-hidden="true"
                          className="fas fa-arrow-right ms-2"
                        ></i>
                      </button>
                    </a>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
          <div className="recent-post-header">
            <Fade direction="right" triggerOnce={true}>
              <div className="recent-post">
                <div>
                  <h3>Recent Posts</h3>
                </div>
                {data.articlesCollection.items
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.sys.publishedAt) - new Date(a.sys.publishedAt)
                  )
                  .slice(0, 3)
                  .map((article) => (
                    <div className="d-flex" key={article.sys.id}>
                      <div>
                        <img src={article.banner.url} alt="" />
                      </div>
                      <div className="ms-3">
                        <a href={`/blog/${article.sys.id}`}>
                          <h6>{article.title}</h6>
                        </a>
                        <p>
                          {new Date(article.sys.publishedAt).toLocaleDateString(
                            "en-GB"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DataBlog;
