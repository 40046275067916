import { Fade } from "react-awesome-reveal";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

const GET_FOOTERDATA = gql`
  query GetFooterData {
    logo(id: "1UdDTD9zq7IZwHu6zITkiu") {
      companyLogo {
        url
      }
    }
    footer(id: "4RNUMvSDzBDug8l2Kyynr7") {
      address
      facebookUrl
      twitterUrl
      instagramUrl
      youTubeUrl
      linkedInUrl
      tikTokUrl
      phoneNumber
      emailAddress
      companyAddress
    }
  }
`;

const Footer = () => {
  const { loading, error, data } = useQuery(GET_FOOTERDATA);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <footer className="">
      <Fade direction="up" triggerOnce={true}>
        <div className="footer-container">
          <div className="container">
            <div className="footer-header">
              <div className="logo-section">
                <div>
                  <Link to="/">
                    <img
                      src={data?.logo.companyLogo.url}
                      alt="logo"
                      width={150}
                    />
                  </Link>
                  <p>{data?.footer.address}</p>
                </div>

                <div className="socials mt-3">
                  <a href={data?.footer.facebookUrl}>
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href={data?.footer.twitterUrl}>
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href={data?.footer.instagramUrl}>
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href={data?.footer.youTubeUrl}>
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href={data?.footer.linkedInUrl}>
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a href={data?.footer.tikTokUrl}>
                    <i className="fab fa-tiktok"></i>
                  </a>
                </div>
              </div>
              <div className="link-section">
                <div className="mb-3">
                  <h5>Quick Links</h5>
                </div>
                <ul>
                  <li>
                    <i className="icon icon-right-arrow1"></i>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="icon icon-right-arrow1"></i>
                    <a href="/About">About Us</a>
                  </li>
                  <li>
                    <i className="icon icon-right-arrow1"></i>
                    <a href="/Services">Services</a>
                  </li>
                  <li>
                    <i className="icon icon-right-arrow1"></i>
                    <a href="/DataBlog">Data Blog</a>
                  </li>
                  <li>
                    <i className="icon icon-right-arrow1"></i>
                    <a href="/Contact">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="company-section">
                <div className="mb-3">
                  <h5>Company</h5>
                </div>
                <div className="d-flex">
                  <i aria-hidden="true" className="fas fa-phone-alt"></i>
                  <div>
                    <p>{data?.footer.phoneNumber}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <i aria-hidden="true" className="fas fa-mail-bulk"></i>
                  <div>
                    <p>{data?.footer.emailAddress}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <i aria-hidden="true" className="fas fa-map-marked-alt"></i>
                  <div>
                    <p>{data?.footer.companyAddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </footer>
  );
};

export default Footer;
