import React from "react";
import { Fade } from "react-awesome-reveal";
import Footer from "../Components/Footer";
import { useQuery, gql } from "@apollo/client";
import HeroSection from "../Components/HeroSection";
import Spinner from "../Components/Spinner";

const GET_ABOUTUSPAGEDATA = gql`
  query GetAboutUsPageData {
    heroSection(id: "5u3ZbAqSwB5JIEZ0nVpdCP") {
      title
      heading
      headline
    }
    aboutUsPage(id: "71iDQiw9lYIhlSjD7URSxU") {
      missionAndVisionHeading
      missionAndVisionTitle
      missionStatement
      missionVisionImage {
        url
      }
      ourTeamLeadHeading
      ourTeamLeadSubHeading
    }
    weHaveGotYouCovered(id: "4ksAnCEUob4ijTZCKxJWTM") {
      sectionTitle
      heading
      headline
      firstPointer
      secondPointer
      thirdPointer
      homePageWhgvcImage {
        url
      }
    }
    ourTeamCollection(limit: 3) {
      items {
        sys {
          id
        }
        name
        jobRole
        displayImage {
          url
        }
      }
    }
  }
`;

const About = () => {
  const { loading, error, data } = useQuery(GET_ABOUTUSPAGEDATA);

  if (loading) return <Spinner />;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="about-container">
      <HeroSection
        title={data?.heroSection.title}
        heading={data?.heroSection.heading}
        subHeading={data?.heroSection.headline}
      />
      <section className=" about-section-attachment-header">
        <div className="container d-flex">
          <div className="growing-demand-data-analytics-header">
            <Fade direction="left" triggerOnce={true}>
              <div className="d-flex mb-4">
                <h5>{data?.aboutUsPage.missionAndVisionHeading}</h5>
              </div>
              <div className="">
                <h1>{data?.aboutUsPage.missionAndVisionTitle}</h1>
              </div>
            </Fade>
            <Fade direction="left" triggerOnce={true}>
              <div className="d-flex mt-3 growing-demand-data-analytics-header-text">
                <p>{data?.aboutUsPage.missionStatement}</p>
              </div>
            </Fade>
          </div>
          <div className="home-attachment-header about-section-attachment-image-header">
            <div className="home-attachment mt-4">
              <Fade direction="right" triggerOnce={true}>
                <img src={data?.aboutUsPage.missionVisionImage.url} alt="" />
              </Fade>
            </div>
            {/* <div className='d-flex'>							
							<div className='home-attachment2'>
								<Fade direction='right' delay={400} triggerOnce={true}>
								<img src={img8} />	
								</Fade>						
							</div>
							<div className='home-attachment3'>
								<Fade direction='right' delay={700} triggerOnce={true}>
								<img src={img7} />
								</Fade>
							</div>
						</div> */}
          </div>
        </div>
      </section>

      <section>
        <Fade direction="right" triggerOnce={true}>
          <div className="services-social">
            <div className="services-social-header">
              <div className="d-flex mb-4">
                <h5>{data?.weHaveGotYouCovered.sectionTitle}</h5>
              </div>
              <div>
                <h1 className="mb-3">{data?.weHaveGotYouCovered.heading}</h1>
                <p className="mb-5">{data?.weHaveGotYouCovered.headline}</p>
              </div>
              <div className="d-flex">
                <i className="fas fa-chevron-circle-right mt-1 me-3"></i>
                <p>{data?.weHaveGotYouCovered.firstPointer}</p>
              </div>
              <div className="d-flex">
                <i className="fas fa-chevron-circle-right mt-1 me-3"></i>
                <p>{data?.weHaveGotYouCovered.secondPointer}</p>
              </div>
              <div className="d-flex">
                <i className="fas fa-chevron-circle-right mt-1 me-3"></i>
                <p>{data?.weHaveGotYouCovered.thirdPointer}</p>
              </div>
            </div>
          </div>
        </Fade>
        <div className="services-social-image-header">
          <Fade direction="left" triggerOnce={true}>
            <img
              src={data?.weHaveGotYouCovered.homePageWhgvcImage.url}
              alt=""
            />
          </Fade>
        </div>
      </section>

      <section>
        <div className="about-background-image2">
          <div className="container user-header">
            <div>
              <div className="d-flex">
                <Fade direction="down" triggerOnce={true}>
                  <i className="fas  fa-dot-circle me-2"></i>
                  <h6 className="">{data?.aboutUsPage.ourTeamLeadHeading}</h6>
                </Fade>
              </div>
              <div>
                <Fade direction="down" triggerOnce={true}>
                  <h2>{data?.aboutUsPage.ourTeamLeadSubHeading}</h2>
                </Fade>
              </div>
            </div>
            <div className="user-images-header d-flex">
              <Fade triggerOnce={true} delay={400}>
                {data?.ourTeamCollection.items.map((item, index) => (
                  <div className="user" key={item?.sys.id}>
                    <img src={item?.displayImage.url} alt="" />
                    <h5>{item?.name}</h5>
                    <p>{item?.jobRole}</p>
                  </div>
                ))}
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
