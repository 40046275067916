import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import Footer from "../Components/Footer";
import HeroSection from "../Components/HeroSection";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../Components/Spinner";

const GET_SERVICESPAGEDATA = gql`
  query GetServicesPageData {
    heroSection(id: "7IK2vjJs5j7XV2pNIlKbPo") {
      title
      heading
      headline
    }
    servicesPage(id: "IQLGwuCystnNTnRAyHOmj") {
      secondSectionHeading
      secondSectionSubHeading
      cardOneHeading
      cardOneDetails
      cardTwoHeading
      cardTwoDetails
      secondSectionImage {
        url
      }
    }
    servicesSectionHeaders(id: "5PB0lB2UyDi0QYdLikPHJv") {
      heading
      subHeading
      whatWeDo
    }
    servicesCollection(limit: 6) {
      items {
        sys {
          id
        }
        heading
        subHeading
        whatWeDo
      }
    }
    weHaveGotYouCovered(id: "6XhaG7ZY5wWFR5uBAnrGJp") {
      sectionTitle
      heading
      headline
      firstPointer
      secondPointer
      thirdPointer
      homePageWhgvcImage {
        url
      }
    }
  }
`;

const Services = () => {
  const { loading, error, data } = useQuery(GET_SERVICESPAGEDATA);

  if (loading) return <Spinner />;
  if (error) return <p>Error : {error.message}</p>;

  function getDirection(index) {
    const directions = ["left", "down", "right", "up", "left", "down"];
    return directions[index % directions.length];
  }

  return (
    <div>
      <HeroSection
        title={data?.heroSection.title}
        heading={data?.heroSection.heading}
        subHeading={data?.heroSection.headline}
      />
      <section>
        <div className="services-background-image2">
          <div className="d-flex container">
            <div className="services-background-image2-header">
              <Fade direction="down" triggerOnce>
                <div className="d-flex">
                  <h5>{data?.servicesPage.secondSectionHeading}</h5>
                </div>
              </Fade>
              <Fade direction="down" triggerOnce>
                <div>
                  <h1>{data?.servicesPage.secondSectionSubHeading}</h1>
                </div>
              </Fade>
              <Fade direction="down" triggerOnce>
                <div className="services-top-choice d-flex">
                  <i class="fa-solid fa-signal ms-4 me-5"></i>
                  <div>
                    <h4>{data?.servicesPage.cardOneHeading}</h4>
                    <p>{data?.servicesPage.cardOneDetails}</p>
                  </div>
                </div>
              </Fade>
              <Fade direction="up" triggerOnce>
                <div className="services-top-choice d-flex">
                  <i class="fa-regular fa-address-card ms-4 me-5"></i>
                  <div>
                    <h4>{data?.servicesPage.cardTwoHeading}</h4>
                    <p>{data?.servicesPage.cardTwoDetails}</p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="services-image-header">
              <Zoom direction="up" triggerOnce>
                <img src={data?.servicesPage.secondSectionImage.url} alt="" />
              </Zoom>
            </div>
            {/* <Zoom triggerOnce>
							<div className='stat'>
								<img src={analytics} />
								<img src={stats} />
							</div>
							</Zoom> */}
          </div>
        </div>
      </section>
      <section className="">
        <div className="background-image-2">
          <div className="container">
            <div className="d-flex services-text-header">
              <Fade direction="down" triggerOnce={true}>
                <div>
                  <div className="d-flex">
                    <h5>{data?.servicesSectionHeaders.heading}</h5>
                  </div>
                  <div>
                    <h1>{data?.servicesSectionHeaders.subHeading}</h1>
                  </div>
                </div>
                <div>
                  <p>{data?.servicesSectionHeaders.whatWeDo}</p>
                </div>
              </Fade>
            </div>
            <div className="background-header d-flex">
              {data.servicesCollection.items.map((service, index) => (
                <div className="background" key={service?.sys.id}>
                  <Fade direction={getDirection(index)} triggerOnce={true}>
                    <div className="d-flex">
                      <div className="background-number">
                        <h6>{index + 1}</h6>
                      </div>
                      <div>
                        <h3>{service?.heading}</h3>
                        <h4>{service?.subHeading}</h4>
                        <p>{service?.whatWeDo}</p>
                      </div>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <Fade direction="right" triggerOnce={true}>
          <div className="services-social-white-bg">
            <div className="services-social-header-white-bg">
              <div className=" mb-4">
                <h5>{data?.weHaveGotYouCovered.sectionTitle}</h5>
              </div>
              <div>
                <h1 className="mb-3">{data?.weHaveGotYouCovered.heading}</h1>
                <p className="mb-5">{data?.weHaveGotYouCovered.headline}</p>
              </div>
              <div className="d-flex">
                <i class="fas fa-chevron-circle-right mt-1 me-3"></i>
                <p>{data?.weHaveGotYouCovered.firstPointer}</p>
              </div>
              <div className="d-flex">
                <i class="fas fa-chevron-circle-right mt-1 me-3"></i>
                <p>{data?.weHaveGotYouCovered.secondPointer}</p>
              </div>
              <div className="d-flex">
                <i class="fas fa-chevron-circle-right mt-1 me-3"></i>
                <p>{data?.weHaveGotYouCovered.thirdPointer}</p>
              </div>
            </div>
          </div>
        </Fade>
        <div className="services-social-image-header-white-bg">
          <Fade direction="left" triggerOnce={true}>
            <img
              src={data?.weHaveGotYouCovered.homePageWhgvcImage.url}
              alt=""
            />
          </Fade>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Services;
