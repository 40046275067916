import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./Pages/About";
import NavBar from "./Components/NavBar";
import Services from "./Pages/Services";
import DataBlog from "./Pages/DataBlog";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import BlogPost from "./Pages/BlogPost";

const App = () => {
  return (
    <main>
      <Router>
        <div className="App">
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/DataBlog" element={<DataBlog />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/blog/:blogId" element={<BlogPost />} />
          </Routes>
        </div>
      </Router>
    </main>
  );
};

export default App;
