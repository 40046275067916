import React from "react";
import { ClipLoader } from "react-spinners";

const Spinner = () => {
  return (
    <div>
      <div className="spinner">
        <ClipLoader
          color="#0058e6"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Spinner;
